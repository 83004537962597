import {
  Box,
  Flex,
  HStack,
  Text,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Show,
  Hide,
  Image,
  Spacer,
  Link,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import Logo from "../assets/icons/logos/logo.png";
import { useEffect, useState } from "react";

interface INavLink {
  label: string;
  href: string;
  isExternal: boolean;
}

const Links = [
  { label: "Home", external: false, href: "/#" },
  { label: "Strategy", external: false, href: "/#strategy" },
  { label: "Town", external: false, href: "/#town" },
  { label: "Team", external: false, href: "/#team" },
  { label: "FAQ", external: false, href: "/#faq" },
  { label: "Whitepaper", external: true, href: "/whitepaper.pdf" },
];

const NavLink = ({ label, href, isExternal }: INavLink) => {
  return (
    <Link href={href} target={isExternal ? "_blank" : ""}>
      <Box
        px={1}
        py={1}
        rounded={"md"}
        color={"gray.600"}
        fontSize={20}
        fontWeight={500}
        _hover={{
          textDecoration: "none",
          color: "gray.800",
        }}
      >
        {label}
      </Box>
    </Link>
  );
};

export default function Navbar() {
  const [fixedPosition, setFixedPosition] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = typeof window === "undefined" || window.innerWidth < 1024;

  const getBgColor = () => {
    return isOpen ? "rgba(255,255,255,1)" : fixedPosition ? "rgba(255,255,255,0.8)" : "";
  };

  const getScroll = () => {
    if (window.scrollY > 25) {
      setFixedPosition(true);
    } else {
      setFixedPosition(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", getScroll);
    return () => {
      window.removeEventListener("scroll", getScroll);
    };
  }, []);

  return (
    <Box px={4} position={"fixed"} w={"100%"} bgColor={getBgColor()} zIndex={200}>
      <Flex h={16} alignItems={"center"}>
        <Link href={"/#"}>
          <HStack>
            <Image alt="Nugget" src={Logo} w={{ base: 8, md: 12 }} />
            <Text color={"black"} fontWeight={750} fontSize={{ base: 20, md: 24 }} pl={0} ml={-2} whiteSpace={"nowrap"}>
              NUGGET FUND
            </Text>
          </HStack>
        </Link>
        <Hide above="md">
          <Spacer />
        </Hide>
        <IconButton
          size={"lg"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          bg="none"
          onClick={isOpen ? onClose : onOpen}
        />
        <Show above="md">
          <Box ml={{ base: 4, md: 8, lg: 16 }} w={"100%"}>
            <Flex as={"nav"} gap={{ base: 4, lg: 8 }} w={"100%"} justifyContent={"flex-end"}>
              {Links.map((link, index) => (
                <NavLink key={index} label={link.label} href={link.href} isExternal={link.external} />
              ))}
              <Link href="https://app.nugget.fund/" target={isMobile ? "_self" : "_blank"}>
                <Button color={"white"} bgColor={"blue.600"} _hover={{ bgColor: "blue.700" }}>
                  Dapp <ExternalLinkIcon w={5} ml={1} />
                </Button>
              </Link>
            </Flex>
          </Box>
        </Show>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ lg: "none" }}>
          <Stack as={"nav"} spacing={4} align={"center"} onClick={onClose}>
            {Links.map((link, index) => (
              <NavLink key={index} label={link.label} href={link.href} isExternal={link.external} />
            ))}
            <Link href="https://app.nugget.fund/" target={isMobile ? "_self" : "_blank"}>
              <Button color={"white"} bgColor={"blue.600"} _hover={{ bgColor: "blue.700" }}>
                Dapp <ExternalLinkIcon w={5} ml={1} />
              </Button>
            </Link>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
