import { Box, Flex, HStack, Text, Image, VStack, Spacer } from "@chakra-ui/react";
import { motion } from "framer-motion";
import Bank from "../assets/images/bank.jpg";
import Blacksmith from "../assets/images/blacksmith.jpg";
import GeneralStore from "../assets/images/general_store.jpg";
import LandOffice from "../assets/images/land_office.jpg";

export default function Town() {
  return (
    <Box overflowX={"hidden"}>
      <div id="town">&nbsp;</div>
      <HStack mt={16} justifyContent={"center"}>
        <Text fontSize={{ base: "4xl", md: "5xl" }} fontWeight={600}>
          Town Structure
        </Text>
      </HStack>
      <motion.div
        initial={{ x: 300 }}
        whileInView={{ x: 0, transition: { type: "easeIn", stiffness: 100, duration: 1.5 } }}
        viewport={{ once: true }}
      >
        <Flex mt={2} gap={2} bgImage={"linear-gradient(rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.3))"}>
          <VStack justifyContent={"center"}>
            <Image src={Blacksmith} maxW={40} />
          </VStack>
          <VStack w={"100%"}>
            <Text fontWeight={700} fontSize={{ base: "md", md: "xl", lg: "2xl", xl: "3xl" }} align={"center"}>
              Blacksmith - Forge
            </Text>
            <Spacer />
            <Text fontSize={{ base: "xs", md: "md", xl: "lg" }} align={"center"}>
              As an investor, the primary way is to go to the Blacksmith’s shop in the app and forge (mint) new $NGET at
              the current price. To become an Investor in Nugget, a drifer (guest) can wander over to the Blacksmith's
              Shop in the app and forge (mint) new $NGET at the current price. This is the start of your journey as a
              Nugget Gulch citizen.
            </Text>
            <Spacer />
            <Spacer />
          </VStack>
        </Flex>
      </motion.div>
      <motion.div
        initial={{ x: -300 }}
        whileInView={{ x: 0, transition: { type: "easeIn", stiffness: 100, duration: 1.5 } }}
        viewport={{ once: true }}
      >
        <Flex mt={2} gap={2} bgImage={"linear-gradient(rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.3))"}>
          <VStack w={"100%"}>
            <Text fontWeight={700} fontSize={{ base: "md", md: "xl", lg: "2xl", xl: "3xl" }} align={"center"}>
              Bank - Staking
            </Text>
            <Spacer />
            <Text fontSize={{ base: "xs", md: "md", xl: "lg" }} align={"center"}>
              Once an investor purchases new $NGET tokens, they will need to go to the Bank and stake those tokens to be
              eligible for rewards. Being staked is the method Nugget Fund will use to determine eligibility for
              rewards. We will use snapshots as needed to determine qualification and amounts.
            </Text>
            <Spacer />
            <Spacer />
          </VStack>
          <VStack justifyContent={"center"}>
            <Image src={Bank} maxW={40} />
          </VStack>
        </Flex>
      </motion.div>
      <motion.div
        initial={{ x: 300 }}
        whileInView={{ x: 0, transition: { type: "easeIn", stiffness: 100, duration: 1.5 } }}
        viewport={{ once: true }}
      >
        <Flex mt={2} gap={2} bgImage={"linear-gradient(rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.3))"}>
          <VStack justifyContent={"center"}>
            <Image src={GeneralStore} maxW={40} />
          </VStack>
          <VStack w={"100%"}>
            <Text fontWeight={700} fontSize={{ base: "md", md: "xl", lg: "2xl", xl: "3xl" }} align={"center"}>
              General Store - OTC
            </Text>
            <Spacer />
            <Text fontSize={{ base: "xs", md: "md", xl: "lg" }} align={"center"}>
              At Nugget Gulch, we recognize that life's uncertainties sometimes necessitate investors to sell their
              $NGET holdings. For this purpose, the General Store will be dedicated to facilitating Over-The-Counter
              (OTC) sales. Here, $NGET holders have the freedom to place sell orders for any quantity of their holdings
              at their chosen price. The Nugget Fund will not dictate prices, as we believe in allowing the free market
              to play its role in determining them.
            </Text>
            <Spacer />
            <Spacer />
          </VStack>
        </Flex>
      </motion.div>
      <motion.div
        initial={{ x: -300 }}
        whileInView={{ x: 0, transition: { type: "easeIn", stiffness: 100, duration: 1.5 } }}
        viewport={{ once: true }}
      >
        <Flex mt={2} gap={2} bgImage={"linear-gradient(rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.3))"}>
          <VStack w={"100%"}>
            <Text fontWeight={700} fontSize={{ base: "md", md: "xl", lg: "2xl", xl: "3xl" }} align={"center"}>
              Land Office - Real World
            </Text>
            <Spacer />
            <Text fontSize={{ base: "xs", md: "md", xl: "lg" }} align={"center"}>
              At the heart of our town's strategy is growth via real world investment. This will be achieved through our
              LLC corporation and the innovative approach of NFT Fractional Ownership. Investors who purchase $SLN and
              commit it will become actual part-owners of the property and receive their share of the profits.
              Information about these investment opportunities will be accessible in the Land Office.
            </Text>
            <Spacer />
            <Spacer />
          </VStack>
          <VStack justifyContent={"center"}>
            <Image src={LandOffice} maxW={40} />
          </VStack>
        </Flex>
      </motion.div>
    </Box>
  );
}
