import {
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

const faqs = [
  {
    question: "Which chain(s) will be supported?",
    answer: "We will start on <b>Arbitrum</b>, and may expand to other chains in the future",
  },
  {
    question: "How do I participate?",
    answer: "You need to <b>mint</b> $NGET token and <b>stake</b> it to earn your passive investment rewards",
  },
  {
    question: "What are the expected monthly returns?",
    answer: "We aim to give monthly returns of <b>4%</b> to all our investors.",
  },
  {
    question: "Will $NGET be listed on a Dex? How do I sell $NGET?",
    answer:
      "<b>No</b>, $NGET will not be listed on any Dex at the moment. If you see any such token, do not buy as it is probably a scam.<br />$NGET can be minted on our Dapp and bought/sold through the <b>OTC</b> section",
  },
];

export default function FAQ() {
  return (
    <motion.div
      // initial={{ y: 200 }}
      // whileInView={{ y: 0, transition: { type: "spring", stiffness: 100, duration: 0.8 } }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1, transition: { type: "easeIn", stiffness: 100, duration: 1 } }}
      viewport={{ once: true }}
    >
      <div id="faq">&nbsp;</div>
      <HStack mt={16} justifyContent={"center"}>
        <Text fontSize={{ base: "4xl", md: "5xl" }} fontWeight={600}>
          FAQs
        </Text>
      </HStack>
      <Accordion my={4} mx={{ base: 1, md: 8, xl: 16 }} bg={"rgba(255, 255, 255, 0.4)"} allowToggle>
        {faqs.map((item, index) => (
          <AccordionItem my={1} key={index}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontSize={{ base: "md", md: "lg" }} fontWeight={600}>
                  {item.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} dangerouslySetInnerHTML={{ __html: item.answer }} />
          </AccordionItem>
        ))}
      </Accordion>
    </motion.div>
  );
}
