import { Box, Flex, Grid, GridItem, Image, Text, VStack } from "@chakra-ui/react";
import Street from "../assets/images/main_street.jpg";

import FAQ from "../components/faq";
import Team from "../components/team";
import Roadmap from "../components/roadmap";
import Footer from "../components/footer";
import Strategy from "../components/strategy";
import Town from "../components/town";

export default function Home() {
  return (
    <Box pt={{ base: "60px", md: "60px" }} px={{ base: "2px", md: "24px", lg: "32px", xl: "40px" }}>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap={2}
        bgImage={"linear-gradient(rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.3))"}
      >
        <GridItem colSpan={{ base: 5, xl: 3 }}>
          <Flex direction={"column"} px={2} h={"100%"} justifyContent={"space-evenly"}>
            <Box>
              <Text fontSize={{ base: "2xl", md: "4xl", xl: "5xl" }} fontWeight={700} align={"center"}>
                Welcome to Nugget Fund
              </Text>
              <Text fontSize={{ base: "xl", md: "2xl", xl: "3xl" }} fontWeight={400} align={"center"}>
                The new home for passive-aggressive investment
              </Text>
            </Box>
            <Text mt={{ base: 4 }} fontSize={{ base: "sm", md: "md", xl: "lg" }}>
              Nugget expertly combines various lucrative strategies, including Mining, Yield Farming, Trading Bots, and
              Real World Investments, to maximize returns for investors. This is guided by the goal of effectively
              managing risk exposure balanced with reward rate. By staking the $NGET token, investors gain access to the
              full array of benefits Nugget offers.
            </Text>
            <Text mt={{ base: 4 }} fontSize={{ base: "sm", md: "md", xl: "lg" }}>
              Our approach shifts the burden away from investors having to manage multiple projects individually, which
              often involves significant risk. Instead, we bring together top-performing investment projects, all
              managed daily by our dedicated team. This strategy ensures that our investors can reap the rewards without
              the constant worry of hands-on management.
            </Text>
            <Text mt={{ base: 4 }} fontSize={{ base: "sm", md: "md", xl: "lg" }}>
              We provide an array of engaging activities to enrich your experience as you effortlessly grow your wealth.
              At the heart of these attractions is The Saloon, a vibrant hub for our community. As we evolve, both
              investors and community members can look forward to an expanding variety of options.
            </Text>
            <Text mt={{ base: 4 }} fontSize={{ base: "sm", md: "md", xl: "lg" }}>
              Additionally, The Saloon serves as the central point for all activities related to our reward token, $SLN,
              offering a dynamic and interactive way to enjoy the benefits of your investment.
            </Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={{ base: 5, xl: 2 }}>
          <VStack>
            <Image src={Street} />
          </VStack>
        </GridItem>
      </Grid>

      <Strategy />
      <Town />
      <Roadmap />
      <Team />
      <FAQ />
      <Footer />
    </Box>
  );
}
