import * as React from "react";
import { ChakraProvider, Box, ThemeConfig, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/navbar";
import Cover from "./assets/images/cover/bg-plain-mirror2.jpg";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({ config });

export const App = () => (
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Box bgImg={Cover} bgSize={"contain"} bgPosition={"center"} minH={"100vh"}>
        <Navbar />
        <Box p={4}>
          <Routes>
            {/* <Route path="/news" element={<News />} /> */}
            <Route path="/*" element={<Home />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  </ChakraProvider>
);
