import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import Poster from "../assets/images/cover/bg-poster-crop.jpg";

const offerings = [
  {
    title: "Cryptocurrency Mining",
    content: (
      <>
        We plan to incorporate mining into our strategy due to it's stable, low-risk option to earn steady returns and
        support the growth of the blockchain. This allows our investors to earn profit from the growth of digital
        currencies, making it a smart choice for our fund.
      </>
    ),
  },
  {
    title: "Trading Strategies",
    content: (
      <>
        We are collaborating with <b>ShinyJackal</b> and his team, who manage many of our trade bots, while also
        overseeing some bots within our own team. By employing proven strategies that maximize returns and protect
        principal, and utilizing a variety of bots trading across different coins and tokens, we effectively minimize
        risk and maximize rewards.
      </>
    ),
  },
  {
    title: "Yield Farming",
    content:
      "We plan to leverage yield aggregators and DEXs to optimize the returns on our investments. This segment of Nugget's investment strategy is highly dynamic with new chains, DEXs, and pools emerging constantly which can result in significant returns for investors.",
  },
  {
    title: "Real World Investments",
    content:
      "Real world projects are crucial to the long-term objectives of Nugget. We firmly believe that combining real-world fractional ownership of assets combined with the blockchain based returns will play a very significant role in the future of cryptocurrency.",
  },
];

export default function Strategy() {
  return (
    <>
      <div id="strategy">&nbsp;</div>
      <HStack mt={16} justifyContent={"center"}>
        <Text fontSize={{ base: "4xl", md: "5xl" }} fontWeight={600}>
          Investment Strategy
        </Text>
      </HStack>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
        {offerings.map((item, index) => (
          <VStack key={index} flexBasis={"34%"} flexGrow={1}>
            <Box
              key={index}
              bgImage={Poster}
              bgSize={"100% 100%"}
              bgRepeat={"no-repeat"}
              p={4}
              maxW={"380px"}
              height={"full"}
            >
              <Text fontWeight={700} fontSize={"3xl"} align={"center"} pt={1}>
                {item.title}
              </Text>
              <Text fontSize={{ base: "lg", md: "xl" }} fontWeight={500} px={4} pt={4} pb={12}>
                {item.content}
              </Text>
            </Box>
          </VStack>
        ))}
      </div>
    </>
  );
}
