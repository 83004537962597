import { HStack, Box, Text, Image, Link } from "@chakra-ui/react";
import DiscordIcon from "../assets/icons/discord.svg";
// import TwitterIcon from "../assets/icons/twitter.svg";
import XIcon from "../assets/icons/x.png";
// import MediumIcon from "../assets/icons/medium.svg";

export default function Footer() {
  return (
    <Box mt={16}>
      <HStack justifyContent={"center"}>
        <Link href="https://discord.gg/DJHG8whaWm" isExternal>
          <Image src={DiscordIcon} w={8} />
        </Link>
        <Link href="https://twitter.com/Nugget_Fund" isExternal>
          <Image src={XIcon} w={8} />
        </Link>
        {/* <Link href="#" isExternal>
          <Image src={MediumIcon} w={8} />
        </Link> */}
      </HStack>
      <HStack justifyContent={"center"} fontWeight={600}>
        <Text>Nugget Fund - Fatu, LLC - {`© ${new Date().getFullYear()}`}</Text>
      </HStack>
    </Box>
  );
}
